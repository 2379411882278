
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Mutations } from '@/store/enums/StoreEnums';
import { VueCookieNext } from 'vue-cookie-next';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'app',
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      const idleTime = 30 * 60 * 1000; // Idle time in milliseconds (30 minutes)
      let timer: ReturnType<typeof setTimeout>;
      const resetTimer = () => {
        clearTimeout(timer);
        timer = setTimeout(logoutAction, idleTime);
      };

      const logoutAction = () => {
        localStorage.clear();

        const cookiesToRemove = [
          '_seip_user',
          'notification_message',
          'day_diff',
          '_seip_employee_id',
          '_entity_id',
          '_institute_info_id',
          '_seip_role_id',
          '_seip_entity_type',
        ];

        const cookieOptions = {
          path: '/',
          domain: '.seip-fd.gov.bd',
        };

        cookiesToRemove.forEach((cookie) => {
          VueCookieNext.removeCookie(cookie, cookieOptions);
        });

        console.log('logout');
        store.commit(Mutations.PURGE_AUTH);
        // redirect to sign in page

        router.push({ name: 'sign-in' });
      };

      const handleUserActivity = () => {
        resetTimer();
      };
      //  Attach event listeners to reset the timer on user activity
      document.addEventListener('mousemove', handleUserActivity);
      document.addEventListener('keydown', handleUserActivity);
      // Initialize the timer
      resetTimer();
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },

});
